import zh_ch from '@/locale/zh-cn'
import zh_tw from '@/locale/zh-tw'
import {createI18n} from "vue-i18n";
import config from "@/utils/config"
import ja from "@/locale/ja";
const messages = {
    'zh-cn': zh_ch,
    'zh-tw': zh_tw,
    'ja': ja
}


const i18n = new createI18n({
    locale: config.locale,
    globalInjection: true,
    messages,
})

export default i18n