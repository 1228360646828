export default {
    global: {
        projectType:{
            type3:'02 新製品発売前戦略',
            type4:'03 SNS口コミアマーケティング',
            type6:'04 統合マーケティングクリエイティブ'
        },
        params:{
            param1:'製品スローガンとスローガンの解釈',
            param2:'プロモーションスローガンとプロモーションスローガンのクリエイティブ説明',
            param3:'プロモーションテーマTheme',
            param4:'コアクリエイティブ概要',
        },
        successMsg: 'おめでとうございます。完了しました！',
        loginFailed: 'ログインエラー。カスタマーサービスにお問い合わせください。',
        errorMsg: 'エラーが発生しました。',
        InsufficientBalance:'残高不足です。チャージしてください',
        upload: {
            notice: 'ファイルサイズが{fileSize}MB以下、形式が{fileTypes}のファイルを最大5個までアップロードしてください',
            exceed: '最大5個までアップロードできます',
            typeError: 'ファイル形式が正しくありません。{fileTypes}形式のファイルをアップロードしてください！',
            sizeError: 'アップロードファイルのサイズは{fileSize}MBを超えることはできません！'
        },
        concurrentError:{
            title:'権限不足',
            content:'現在タスクが進行中です。AIに同時に複数のタスクを処理させたい場合は、ビジネス担当者に連絡してスーパーメンバーにアップグレードしてください',
            ok:'了解しました'
        },
        title:{
            marketingTask:'今回のADGoタスク'
        },
        button:{
            clear:'クリア',
            expand:'AIによる補完',
            submit: '送信',
            download: 'ダウンロード',
            confirm: '確認',
            cancel: 'キャンセル'
        },
        message:{
            emptyMarketingTask:'マーケティングタスクを入力してください'
        }
    },
    views: {
        LoginPage: {
            title: 'ようこそ',
            username: 'アカウントを入力してください',
            password: 'パスワードを入力してください',
            login: 'ログイン',
            qrcode:{
                title:'オンラインサポート'
            }
        },
        TemplateSelection: {
            pageDesc: 'クリエイティブインスピレーション生成AI',
            template1: {
                title: '02 新製品発売前戦略',
                desc: '新製品発売のプロモーションのため、プロモーション戦略分析とプロモーションスローガンのクリエイティブを導き出します。',
                dialog: {
                    suitableScene: '新製品発売、製品プロモーションスローガン作成、プロモーション戦略の立案が必要',
                    noIdea: {
                        output1: 'ターゲット洞察クリエイティブタグ',
                        output2: '新製品プロモーションスローガンクリエイティブ',
                    },
                    hasIdea: {
                        output1: '市場分析',
                        output2: '競合分析',
                        output3: '製品セールスポイント分析',
                        output4: 'ターゲット分析',
                        output5: '勝利のプロモーション戦略',
                        output6: '新製品プロモーションポジショニング',
                        output7: '新製品プロモーションスローガン'
                    }
                }
            },
            template2: {
                title: '03 SNS口コミアマーケティング',
                desc: '製品のSNSプラットフォームでのプロモーションのため、ブランドのSNSマインドシェア、プロモーションテーマとコアクリエイティブを導き出します。',
                dialog: {
                    suitableScene: '製品/ブランド/イベント、SNSプラットフォームでのプロモーション、SNSクチコミマインドシェアの創出',
                    noIdea: {
                        output1: 'ターゲット洞察',
                        output2: 'プロモーションテーマとコアクリエイティブ',
                    },
                    hasIdea: {
                        output1: 'SNSクチコミマインドシェア',
                        output2: '差別化されたコアバリューの定義',
                        output3: 'ターゲット分析',
                        output4: 'ターゲット洞察クリエイティブタグ',
                        output5: '勝利のマインドシェア戦略',
                        output6: 'プロモーションテーマとコアクリエイティブ',
                        output7: 'クリエイティブコピーフレーズ',
                    },
                    notice:'注意：このプランには市場分析、競合分析、製品セールスポイント分析は含まれていません。必要な場合は"新製品発売前戦略"を選択してください。'
                }
            },
            template3: {
                title: '04 統合マーケティングクリエイティブ',
                desc: '製品のSNSプラットフォームでのプロモーションのため、SNSホットトピックを作り出し、統合マーケティングクリエイティブプランを完成させます。',
                dialog: {
                    suitableScene: '製品/ブランド/イベント、SNSプラットフォームでのプロモーション、統合マーケティング全体のクリエイティブの創出',
                    noIdea: {
                        output1: 'ターゲット洞察',
                        output2: 'プロモーションテーマとコアクリエイティブ',
                        output3: 'Buzz Point ホットトピッククリエイティブ',
                    },
                    hasIdea: {
                        output1: 'ターゲット洞察',
                        output2: 'プロモーションテーマとコアクリエイティブ',
                        output3: 'Buzz Point ホットトピック',
                        output4: 'Teaser フェーズクリエイティブとトピック',
                        output5: 'Launch フェーズクリエイティブとトピック',
                        output6: 'Sustain フェーズクリエイティブとトピック',
                        output7: '拡散/コンバージョン/プラットフォーム選択',
                        output8: 'KOL ブレイクスルートピッククリエイティブ',
                        output9: 'KOL レベル推奨とトピッククリエイティブ',
                        output10: 'KOL 配置マトリックス',
                    }
                }
            },
            template4: {
                title:'01 市場情報調査',
                desc:'市場戦略の迅速な策定、競合ブランドの理解、消費者洞察、製品セールスポイント分析、マーケティングニーズ分析。',
                dialog:{
                    suitableScene:'市場戦略の迅速な策定、競合ブランドの理解、消費者洞察、製品セールスポイント分析、マーケティングニーズ分析。',
                    outputs:{
                        output1:'市場競争状況レポート',
                        output2:'競合ブランド比較レポート',
                        output3:'自社製品の優位性セールスポイントと消費者評価',
                        output4:'ターゲット層の製品洞察レポート',
                        output5:'業界メディア出稿状況レポート',
                        output6:'競合メディア出稿状況レポート',
                        output7:'競合プロモーション戦略分析レポート'
                    }
                }
            },
            template5: {
                title: '07 ショート動画クリエイティブ',
                desc: 'セールスポイントの整理、ユーザー購買ポイントの発掘、商品販売ショート動画脚本クリエイティブ、撮影脚本',
                dialog: {
                    suitableScene: 'セールスポイントの整理、ユーザー購買ポイントの発掘、商品販売ショート動画脚本クリエイティブ、撮影脚本',
                    outputs: {
                        output1: '10大製品セールスポイント',
                        output2: '10大ユーザー購買ポイント',
                        output3: 'セールスポイントと購買ポイントに基づく脚本クリエイティブ',
                        output4: 'クリエイティブに基づく実際の撮影脚本',
                    }
                },
                alias: '販売Go'
            },
            template6: {
                title: '05 グローバルクリエイティブケース',
                desc: '参考ケースの検索、ケースに基づくクリエイティブの創出、クリエイティブの検証のためのケース探し',
                dialog: {
                    suitableScene: '参考ケースの検索、ケースに基づくクリエイティブの創出、クリエイティブの検証のためのケース探し',
                    outputs: {
                        output1: '6つの参考ケース',
                        output2: '対応ケースのクリエイティブTips',
                        output3: 'クリエイティブに類似したケースの探索'
                    }
                }
            },
            template7: {
                title: '06 究極のクリエイティブブレインストーミング',
                desc: 'ブレインストーミングの爆発的バージョン、大量！高速！様々な優れたクリエイティブインスピレーションを生み出し、インスピレーション枯渇を解消します。',
                dialog: {
                    suitableScene: 'IDEA探索、IDEA拡張',
                    outputs: {
                        output1: '5つのIDEA'
                    }
                }
            },
            template8: {
                title: '08 マーケティングコンセプト画像生成',
                desc: 'マーケティングクリエイティブを直接ポスターレベルの画像に生成',
                dialog: {
                    suitableScene: 'プラン用イラスト、クリエイティブビジュアル参考、ポスター/KV参考、社内ブレインストーミングインスピレーション',
                    outputs: {
                        output1: 'ポスターレベルの画像',
                    }
                },
            },
            modal: {
                suitableScene: '適用シーン：',
                noIdea: 'ブレインストームモード出力',
                hasIdea: '完全プラン出力',
                output: '出力',
                ok: '了解しました'
            },
            recentProject: {
                title: '最近のプラン',
                mode: {
                    noIdea: 'ブレインストームモード',
                    hasIdea: '完全プランモード',
                    research: '情報調査',
                    shortVideoScript: 'ショート動画クリエイティブ',
                    tips: {
                        reference: 'タスク検索',
                        similar: 'クリエイティブ検索'
                    },
                    explosion: {
                        explore:'IDEA探索',
                        expand:'IDEA拡張'
                    }
                }
            },
            msg:{
                title:'権限不足',
                content:'この機能はまだ開通していません。使用するには先に有料購入が必要です',
                ok:'支払いへ',
                cancel:'キャンセル'
            },
            announcement:{
                title:'ADGo新機能',
                content: '<div>マーケティングコンセプト画像生成：マーケティングコンセプトや製品活動内容を直接入力するだけで、ポスターレベルの参考画像を生成できます。ビジュアルコンセプト参考がこれほど簡単になったことはありません！</div>',
                ok:'了解しました'
            }
        },
        ModeSelection: {
            button: {
                return: '戻る',
                generate: 'ブリーフを生成',
                research: 'リサーチを開始'
            },
            pageDesc: '今日は何を創造したいですか？',
            noIdea: 'ブレインストームモード',
            hasIdea: '完全プランモード',
            giveWhat:{
                idea:"たくさんの素晴らしいアイデア",
                plan:"完璧な思考とプラン"
            },
            label1:'今回のマーケティングタスク（必須）：',
            label2:'コミュニケーションテーマ（任意、未入力の場合はADGoが考案します）：',
            label3:'コアクリエイティブ概要（任意、未入力の場合はADGoが考案します）：',
            label4:'コミュニケーションテーマコピー（任意、未入力の場合はADGoが考案します）：',
            label5:'コミュニケーションテーマクリエイティブコンセプト説明（任意、未入力の場合はADGoが考案します）：',
            label6:'クレイジーモード：',
            tooltip6:'オンにすると、斬新なクリエイティブアイデアが得られます',
            label7: 'インターネット検索：',
            tooltip7: 'オンにすると、必要に応じてAIがインターネットから情報を収集します',
            label8: '<span class="upload-text">アップロード</span>をクリックしてデータを追加（任意）：',
            placeholder8: '今回のマーケティングタスクに関連する資料（製品紹介資料、市場調査レポート、ターゲット層インサイトレポート、消費者フィードバック等）をアップロードしてください。AIは現在画像を読み取ることができないため、テキスト版の提供をお願いします',
            label9: 'スマート生成：',
            tooltip9: 'オンにすると、AIが今回のマーケティングタスクを確認し、補足提案を行います',
            placeholder1: '今回の新製品発売におけるマーケティングタスクの要件をAIに伝えてください。以下の情報を含めることができます：ブランド名/製品またはキャンペーン名/製品またはキャンペーンのセールスポイント/ターゲット層の基本情報/競合ブランドの製品またはキャンペーン/競合ブランドの製品またはキャンペーンのセールスポイント/指定メディアプラットフォームとメディア活用方法/今回のコミュニケーションの目的または期待される効果/指定のセレブリティ、コラボIP、異業種コラボレーション。\n\n参考が必要な場合は、以下の例をクリックしてください。',
            placeholder2: '完成したコミュニケーションテーマクリエイティブを入力してください。以下を含めることができます：コミュニケーションテーマコピー/コミュニケーションテーマクリエイティブ説明。ADGoは提供されたコミュニケーションテーマに基づいて、プラン全体を完成させます。\n\n参考が必要な場合は、以下の例をクリックしてください。',
            placeholder3: '完成したメインクリエイティブを入力してください。以下を含めることができます：クリエイティブコンセプト/クリエイティブ手法/メディアクリエイティブ/クリエイティブコンテンツ/等の具体的な実施クリエイティブ。ADGoは提供されたメインクリエイティブ説明に基づいて、プラン全体を完成させます。\n\n参考が必要な場合は、以下の例をクリックしてください。',
            placeholder4: '完成したコミュニケーションテーマコピーを入力してください。以下を含めることができます：コミュニケーションテーマコピー/スローガン。ADGoは提供されたコミュニケーションテーマコピーに基づいて、プラン全体を完成させます。\n\n参考が必要な場合は、以下の例をクリックしてください。',
            placeholder5: 'コミュニケーションテーマコピーのクリエイティブコンセプト説明を入力してください。以下を含めることができます：クリエイティブコンセプト/クリエイティブインサイト/コピー手法/クリエイティブの源泉/人々を動かす理由/等のコピークリエイティブの詳細説明。ADGoは提供されたコミュニケーションテーマクリエイティブに基づいて、プラン全体を完成させます。\n\n参考が必要な場合は、以下の例をクリックしてください。',
            example: {
                title: '参考例',
            },
            example1:{
                title:'例1',
                brief:'Under armourは2024年に新しい靴slipspeedを発売予定です。その主なセールスポイントは、かかとが折りたためることで、折りたたむと普段使いのスリッパとして、かかとを立てるとプロフェッショナルなトレーニングシューズとして使用できます。ブランドはこの新製品発売を通じて、製品のプロモーションと同時に、消費者の中でのハードコアプロフェッショナルというブランドイメージを突破し、より多くの層に拡大したいと考えています。この靴の対象年齢は18-30歳で、主な競合製品はNike Metcon 9、Reebok Nano X4です。',
                spreadTheme:'足元から解き放て、無限の可能性\n',
                coreIdea:'このクリエイティブは、現代都市生活の多面性とSlipSpeedの折りたためるかかとという独特なデザインから生まれ、"解き放つ"という想像力豊かなコンセプトを創造しました。これは製品の変形機能を示唆するだけでなく、生活における無限の可能性を解き放つという意味も込められています。このテーマは若者の効率性と多様性への追求に応え、生活の簡素化と質の向上への共感を引き出します。同時に、現在流行の"新しい使い方を解き放つ"というコミュニティのホットトピックを巧みに活用し、ユーザーの好奇心と参加意欲を刺激し、"新しくて面白い"というコミュニティの動機に合致しています。\n'
            },
            example2:{
                title:'例2',
                brief:'ネスレのアラビカコーヒー品種「Star 4」は、低炭素排出とサプライチェーンの最適化を強調し、特に環境意識が高く品質を重視するコーヒー愛好家を引きつけ、台湾市場での生活の質と環境の持続可能性を重視する都市部の人々を正確にターゲットとしています。このコーヒーは独特な風味を持つだけでなく、気候変動への対応における革新性を特に強調し、日常的な飲用や特別な機会での飲用に適しています。他の高級コーヒーブランドと比較して、ネスレの環境保護における革新性、品質管理、およびグローバルサプライチェーンの最適化における優位性を強調しています。',
                spreadTheme:'「一杯のコーヒー、一つの責任」\n',
                coreIdea:'環境保護意識が日々高まるこの時代において、ネスレStar 4はお客様のコーヒーの味への厳しい要求を満たすだけでなく、環境保護への道徳的期待も満たしています。Star 4の一杯一杯は、生活の質と環境保護という二重の約束の表れであり、それはただのコーヒーではなく、未来への責任でもあります。\n'
            },
            example3:{
                title:'例1',
                brief:'ファミリーマートの「新学期キャンペーン」活動 活動内容：ファミリーマートはDcardで大学生向けの「新学期キャンペーン」を開催し、様々な割引や特典情報を発信すると同時に、Dcard上で質問回答形式の交流を行い、学生からの様々な質問に答えます。明確な目標：大学生層を正確にターゲットとし、活動デザインは彼らのニーズと好みに合わせています。プラットフォーム交流：Dcardのコミュニティ交流機能を活用し、ユーザーの参加度とブランドの好感度を向上させています。実用性が高い：実際の割引と特典を提供し、直接的に店舗の売上を向上させます。競合ブランド：セブンイレブン、ライフ、ファミリーマートの新学期キャンペーン。',
                spreadTheme:'「ファミマの深夜食堂：夜更かし勉強家編」\n' +
                    'クリエイティブ説明：ファミリーマートは日本の有名漫画IP『深夜食堂』とコラボレーションし、「ファミマの深夜食堂：夜更かし勉強家編」特別企画を展開します。『深夜食堂』の心温まる癒しの雰囲気と台湾の学生の夜更かし学習生活を組み合わせ、心温まる漫画短編とアニメーションを制作します。\n',
                coreIdea:'' +
                    '主なコンテンツには以下が含まれます：漫画連載：『深夜食堂』の原作者・安倍夜郎氏に台湾の大学生を主人公とした特別編を制作依頼し、毎週ファミマアプリで1話ずつ更新し、学生たちがファミリーマートで温かさと力を見つける物語を語ります。インタラクティブアニメーション：漫画の内容に基づいて短編アニメーションを制作し、ユーザーが特定のシーンで選択を行い、異なるストーリー展開を体験できるようにします。インタラクションを完了したユーザーは限定版「ファミマの深夜食堂」グッズを獲得できます。実店舗テーマ店：台湾全土の大学周辺の指定されたファミリーマート店舗で「深夜食堂」テーマエリアを設置し、装飾スタイルは漫画のシーンを模倣するだけでなく、漫画に登場する特徴的な料理も提供し、学生たちに漫画の中の温かい雰囲気を体験させます。このIPコラボレーションを通じて、『深夜食堂』の既存ファンを引きつけるだけでなく、より多くの学生にファミリーマートが漫画の中の「深夜食堂」のように、彼らの夜更かし学習時の温かく頼りになる存在であることを感じてもらいます。\n'
            },
            example4:{
                title:'例2',
                brief:'X-TRAIL新製品プロモーション活動 活動内容：X-TRAILは、結婚して子供がいる40-54歳の男性、トライアスロン、ロードランニング、キャンプなどのアウトドア活動を好む消費者層向けに新モデルを発売し、家族でのお出かけに適した広々とした快適な車内空間を強調します。FacebookとInstagramでアウトドア旅行プランと家族の思い出の物語を共有し、ユーザーに家族旅行の写真の共有を促します。明確な目標：アウトドア活動を好み、頻繁に家族を乗せて出かける中年男性を正確にターゲットとし、製品設計が彼らのライフスタイルとニーズに合致することを確保します。プラットフォーム交流：ソーシャルメディアのストーリー機能を活用し、ユーザーの参加度とブランドの感情的つながりを向上させます。実用性が高い：製品の空間の快適性と多機能性を強調し、購入意欲を高めます。競合ブランド：Toyota RAV4、Honda CR-Vの家族向けアウトドア活動プロモーション活動。',
                spreadTheme:'"素晴らしさを開く、X-Trailと共に歩む"\n' +
                    'コミュニケーションテーマクリエイティブ説明\n' +
                    '今回のコミュニケーションテーマ"素晴らしさを開く、X-Trailと共に歩む"は、ターゲット層の家族との時間重視と質の高い生活への追求から生まれました。Nissan X-Trailの広々とした空間や安全技術などの製品特性と組み合わせ、消費者が家族と過ごす時間の重要性と素晴らしさを感じられる独特な視点を創造します。\n\n',
                coreIdea:'Nissan X-TRAILは革新的なオンラインからオフラインへの統合マーケティング活動"X-TRAIL家族の冒険プラン"を展開し、家族間の交流と製品体験の向上を目指します。この活動には、インタラクティブなウェブサイトプラットフォームが含まれ、家族メンバーはここで旅行ルートを計画し、旅行先に応じたカスタマイズされた安全運転ガイドと旅行のヒントを得ることができます。\n'
            },
            example5:{
                title:'例3',
                brief:'台湾のケンタッキーは2024年に4年前の人気限定商品"大阪ボーンレスクリスピー"シリーズを復活させます。セールスポイントは、和風照り焼きソースとボーンレスクリスピーチキン、焼きそばの組み合わせで、ターゲットは日本文化と和風の味を好む若い消費者、および新しい味を試すことを好むグルメファンです。台湾のセレブリティを起用し、台湾のソーシャルメディアプラットフォームでプロモーションを行う必要があります。競合ブランド：マクドナルド、モスバーガーの和風限定メニュープロモーション活動。',
                spreadTheme:'「味覚の旅、和風の味わい一期一会」\n' +
                    'コミュニケーションテーマクリエイティブ説明：\n' +
                    'このテーマは「味覚の旅」というソーシャルメディアのトレンドを活用し、若者の新しい発見と探求への心理的ニーズに合致しています。「大阪ボーンレスクリスピー」の和風照り焼きの味わいと組み合わせ、このクリエイティブは料理を味わうことが味覚の日本旅行のようだと描写するだけでなく、消費者にこのユニークな味わいを探求し、味わうことへの挑戦も促します。\n',
                coreIdea:'ケンタッキー台湾は台湾で最も人気のある音楽ストリーミングプラットフォームKKBOXと提携し、「味覚ミュージックフェスティバル」を展開します。KKBOXプラットフォームで特別企画の「大阪ボーンレスクリスピー音楽シングル」を配信し、各楽曲が特定の味のボーンレスクリスピーと関連付けられ、消費者は料理を楽しみながらカスタマイズされた音楽体験を楽しむことができます。さらに、ケンタッキーはKKBOXでオンラインミュージックフェスティバルを開催し、台湾のローカルアーティストを招き、イベント期間中に独占クーポンを提供して、ユーザーに大阪ボーンレスクリスピーを注文してもらいます。ユーザーはプレイリストの共有や料理レビューの投稿などのオンラインインタラクションに参加することで、限定グッズを獲得できます。\n'
            },
            msgBox:{
                check:{
                    title:'ユーザータスクアシスタント',
                    ok:'戻って修正',
                    cancel:'了解しました'
                }
            },
            loading:{
                check:'AIがマーケティングタスクを確認中です',
                expand:'AIがマーケティングタスクを補完中です',
                title:'AIがマーケティングタスクのタイトルを作成中です',
                brief:'AIがブリーフを作成中です'
            }
        },
        Workspace: {
            functionMenu:{
                function1: '新製品発売前戦略',
                function2: 'SNS口コミアマーケティング',
                function3: '統合マーケティングクリエイティブ',
                function4: '市場情報調査',
                function5: 'ショート動画クリエイティブ',
                function6: 'グローバルクリエイティブケース',
                function7: '究極のクリエイティブブレインストーミング',
                function8: 'マーケティングコンセプト画像生成'
            },
            historyMenu:{
                title:'履歴'
            },
            copyright: 'アイシーズ制作',
            menu: {
                team: 'チーム',
                usage: '使用状況',
                settings: '設定',
                exit: 'ログアウト'
            },
            modal:{
                update:{
                    title:'ブリーフ名の変更',
                    placeholder:'ブリーフ名を入力してください'
                },
                delete:{
                    message1:'この操作でブリーフは完全に削除されます。',
                    message2:'続行しますか？'
                },
                settings:{
                    title:'設定',
                    tab1:{
                        title:'一般',
                        label1:{
                            word1:'ユ',
                            word2:'ー',
                            word3:'ザ',
                            word4:'ー',
                            word5:'名:',
                        },
                        label2:{
                            word1:'メ',
                            word2:'ー',
                            word3:'ル:',
                        },
                        label3:{
                            word1:'言',
                            word2:'語',
                            word3:'環',
                            word4:'境:',
                        },
                        select:{
                            option1:'簡体字中国語',
                            option2:'繁体字中国語',
                        },
                        label4:{
                            word1:'チ',
                            word2:'ー',
                            word3:'ム',
                            word4:'メ',
                            word5:'ン',
                            word6:'バ',
                            word7:'ー:',
                        },
                        label5:'すべてのチャットを削除',
                        button5:'すべて削除',
                        label6:'パスワードの変更',
                        button6:'クリックして変更'
                    },
                    tab2:{
                        title:'検索設定',
                        label1:{
                            word1:'地',
                            word2:'域',
                        },
                        label2:{
                            word1:'言',
                            word2:'語',
                        },
                        placeholder1:'地域を選択してください',
                        placeholder2:'言語を選択してください'
                    },
                    changePassword:{
                        step1:{
                            button1:"携帯電話で変更",
                            button2:"メールで変更",
                            notice:"パスワードを変更する前に、管理者に連絡して携帯電話番号またはメールアドレスを設定してください"
                        },
                        step2:{
                            notice1:"アカウントのセキュリティのため、携帯電話の確認が必要です",
                            notice2:"アカウントのセキュリティのため、メールアドレスの確認が必要です",
                            label1:"認証コード",
                            placeholder1:"認証コードを入力してください",
                            button1:"認証コードを取得",
                            button2:"再送信"
                        },
                        step3:{
                            label1:"新しいパスワード",
                            placeholder1:"新しいパスワードを入力してください",
                            label2:"パスワードの確認",
                            placeholder2:"新しいパスワードをもう一度入力してください",
                        },
                        buttons:{
                            ok:"確認",
                            cancel:"キャンセル"
                        }
                    }
                }
            }
        },
        MoreIdea: {
            button: {
                cancel: 'キャンセル',
                ok: '完了',
                help:'使用ガイド',
                finishEdit: '置換完了',
                cancelEdit: '置換キャンセル',
                moreIdea: 'もっと案を出して',
                replace: '原文を置換',
                export:'ダウンロード形式',
                wordCn:'Word（中国語）',
                wordEn:'Word（英語）',
                freeMind:'FreeMind',
                markdown:'Markdown'
            },
            message:{
                error1:"ブレインストームする提案内容を選択してください",
                error2:'少なくとも1つのオプションを選択してください',
                error3:'1つのオプションのみ選択可能です'
            },
            tour:{
                pre:'前へ',
                next:'次へ',
                finish:'理解しました'
            },
            tour1:{
                step1:'ここで置換したい内容を選択',
                step2:'より多くのアイデアを取得（最大5回まで）',
                step3:'ここでより多くのアイデアを確認',
                step4:'ページをめくる',
                step5:'アイデアを選択して原文の対応する箇所に置換'
            },
            tour2:{
                step1:'AIは元のメイン推奨アイデアをブレインストーム待ち区域に配置しました。「ブレインストーム」をクリックしてより多くのアイデアを生成できます。満足のいくアイデアが見つかったら、それをチェックして「原文を置換」をクリックすると、左側の原案の対応する部分が置換されます'
            }
        },
        FullResearch:{
            placeholder1:'情報調査の内容をAIに伝えてください。例：xxx製品が来月発売予定です。この製品と競合製品、業界と市場に関する調査レポートを作成してください'
        },
        ShortVideoScript: {
            label1: '今回のプロモーション商品（必須）',
            label2: 'スマート脚本タイプの選択（任意）',
            label3: '撮影条件（任意）',
            placeholder1: '今回撮影するプロモーション動画の商品についてできるだけ詳しく説明してください。実物商品でも、映画、コンサート、ライブ、ゲームなどのサービス商品でも構いません',
            placeholder2: 'ナレーション/ドラマ/開封/シーン活用/プロモーション/楽しい交流 など',
            placeholder3: '撮影必須条件：動画に必ず含める要素を入力してください。参考例（以下に限定されません）：\n' +
                '1. 商品（パッケージ、色、サイズ...）\n' +
                '2. 出演者の役柄（恋人、同僚、母親...）\n' +
                '3. 場所の説明（自宅、レストラン、海辺...）\n' +
                '4. 天気（晴天、雨天、豪雨...）\n' +
                '5. 時間帯（夕暮れ、昼間、深夜...）\n' +
                '6. イベント（ディナー、結婚式、同窓会...）\n' +
                '7. 背景シーン（会話、喧嘩、トークショー）\n' +
                '8. 衣装、メイク、小道具\n' +
                '9. 特別に指定が必要な撮影内容\n',
            placeholder4: '今回のプロモーション商品に関する紹介資料/情報PDFをアップロード',
            placeholder5: '類似業界または商品のヒット商品プロモーション動画脚本をアップロード',
            button: {
                uploadInfo: '商品情報<span class="upload-text">アップロード</span>（任意）',
                uploadReference: 'ヒット脚本<span class="upload-text">アップロード</span>（任意）',
                generateIdea: '開始'
            },
            example1:{
                marketingTask:'資生堂\n' +
                    '商品名：資生堂化粧水\n' +
                    'ニックネーム：資生堂レッドウォーター\n' +
                    '効果：肌の美白\n' +
                    'コアセールスポイント：肌を傷めずにやさしくケア、持続的な美白効果\n' +
                    'セールスポイントの根拠：1.マイクロエキス成分；2.ジェル核包装技術\n',
                scriptCondition:'コンテンツ要件\n' +
                    '1. 最初の3秒間、以下から1つ選択\n' +
                    '好奇心喚起法 – "独身の女性たちが使っているこれ、秘密の必殺技を教えます"\n' +
                    'マーケティング暗示法 – この商品、常に品切れになっています\n' +
                    '質問導入法 – 皆さん、お肌がどれだけ傷つきやすいか知っていますか\n' +
                    '生活導入法 – たとえ1日1分でも、必ずやるべきスキンケアは何でしょう？\n' +
                    '感嘆効果法 – みなさん、このレッドウォーター、本当に最高です\n' +
                    'ターゲット訴求法 – 夜更かしで失われるのは私たちの若さ\n' +
                    '2. 中盤、以下から1つ選択、ただしどの内容でも肌を傷めないというセールスポイントを必ず含める\n' +
                    'ブランド力+使用感+評価 – セールスポイントを簡潔に伝え、使用感を強調\n' +
                    '商品紹介+成分紹介+使用デモ – これは肌の状態が良い必要あり、顔のアップ撮影\n' +
                    '3. エンディング、非常に自信を持ってフォロワーに購入を勧め、"推す価値あり、買う価値あり"という感覚を表現\n' +
                    '\n' +
                    '撮影要件：\n' +
                    '1. 商品の各アングルのアップショットを15秒以上（LOGOに反射なし；指紋や汚れなどなし、商品は鏡像にしない！）\n' +
                    '2. 商品の露出/商品の使用シーン/画像を含める必要あり\n' +
                    '3. 画面の色調は過度な加工を避け、一般ユーザーが自然に商品の感想を共有する雰囲気を作るが、必ず清潔に\n' +
                    '4. 背景は清潔で見栄えの良いもの\n' +
                    '5. 動画は必ず縦向きで撮影\n'
            },
            example2:{
                marketingTask:'故宮新商品_春の遊びミニトートバッグ\n' +
                    'ブランド背景：故宮新商品は故宮博物院唯一の公式コンテンツIPで、現在まで億単位のファンを獲得し、故宮文化の継承者です。トップアーティストとトップインフルエンサーの支持を受け、数々の話題商品とヒット商品を生み出し、東洋美学を代表する文化革新のシンボルとなっています。\n' +
                    '商品名：春の遊びミニトートバッグ\n' +
                    'コアセールスポイント：特別なデザインポイントとして、持ち手は負荷点を考慮した設計で、長時間の使用でも手が疲れにくく、バランスの取れた負荷分散により、より丈夫で耐久性があります。表面フレーム部分に立体感を持たせることで、バッグ全体の高級感を高めています。\n' +
                    'セールスポイントの根拠：デザインのインスピレーションは『朱瞻基行楽図』の一場面から得ており、当時の娯楽の様子を表現しています。"雲淡風輕近午天，傍花隨柳過前川。時人不識余心樂，將謂偷閒學少年"という宋代の詩人程顥が描いた春の日差しと遊びの中での自由な心情をデザインに融合させ、春の暖かさと花々を"バッグ"に込めています。\n' +
                    'コーディネート提案：手持ちでも長いショルダーストラップで斜め掛けでも使用可能。立体的な工芸により、形を保ちながら収納スペースも広がります。開閉はファスナー式で、物の紛失を防ぎ安心感を高めます。控えめで優しい色合いは、どんな低彩度の服装とも合わせやすいです。\n',
                scriptCondition:'コンテンツ要件\n' +
                    '冒頭：故宮新商品は故宮博物院唯一の公式ファッションバッグブランドで、セレブ愛用モデルとして数々の話題とヒット商品を生み出し、東洋美学を代表する文化革新のシンボルです。\n' +
                    '中盤：手持ちでも長いショルダーストラップで斜め掛けでも使用可能。立体的な工芸により、形を保ちながら収納スペースも広がります。開閉はファスナー式で、物の紛失を防ぎ安心感を高めます。控えめで優しい色合いは、どんな低彩度の服装とも合わせやすいです。\n' +
                    'エンディング：故宮文創バッグは高級ブランドの品質を国産品の価格で提供し、東西の文化を融合させた優れた商品を作り出しています\n' +
                    '撮影要件\n' +
                    '故宮のシーンと故宮新商品のセレブシーンを含める必要あり\n' +
                    '商品のクローズアップシーンを含める必要あり\n' +
                    'モデルがバッグを身につけているシーンを含める必要あり\n'
            },
            example3:{
                marketingTask:'禾日ざくろジュース\n' +
                    '商品名：NFC低温フレッシュ搾りピュアざくろジュース\n' +
                    '商品セールスポイント：\n' +
                    '1、砂糖・水無添加のピュアフレッシュ搾りざくろジュース\n' +
                    '2、生果実搾り、濃縮還元ではない\n' +
                    '3、HPP超高圧殺菌技術\n' +
                    '4、全工程コールドチェーン輸送\n' +
                    '5、栄養たっぷりの原液\n' +
                    '6、3個のざくろから1本のジュースを搾る\n' +
                    '7、果汁含有量100%\n' +
                    '8、一滴の水も加えない\n' +
                    '9、8本セット、コストパフォーマンス高い\n' +
                    '\n' +
                    '購入者層：ホワイトカラー、ジュース愛飲者、美白志向者、健康志向者、フルーツ愛好者\n',
                scriptCondition:'コンテンツ要件\n' +
                    '冒頭：季節限定/フレッシュ搾り/3個のざくろから1本/原材料表をアピール\n' +
                    '中盤：セールスポイントの紹介\n' +
                    'エンディング：健康・コストパフォーマンスなどをアピール\n' +
                    '\n' +
                    '撮影要件\n' +
                    '使用シーンのショットを含める必要あり\n' +
                    'クローズアップショットを含める必要あり\n' +
                    '機能展示ショットを含める必要あり\n' +
                    '人と商品の相互作用ショットを含める必要あり\n'
            },
            scriptTypes: {
                type_1: 'ナレーション系',
                type_1_1: 'カメラ目線解説型',
                type_1_2: 'ナレーション音声型',
                type_1_3: 'Q&A対話型',
                type_1_4: '専門家解説型',
                type_2: 'ドラマ系',
                type_2_1: '生活シーン再現',
                type_2_2: 'ビフォーアフター比較',
                type_2_3: '購入ポイント解決ドラマ',
                type_2_4: '商品使用ミニストーリー',
                type_2_5: '心温まるミニドラマ',
                type_3: 'デモンストレーション系',
                type_3_1: '商品機能展示',
                type_3_2: '使用手順チュートリアル',
                type_3_3: '開封レビュー',
                type_3_4: '比較実験',
                type_3_5: '実際の使用効果',
                type_4: 'エンターテイメント系',
                type_4_1: 'クリエイティブエフェクト',
                type_4_2: 'トランジション編集',
                type_4_3: 'ダンス組み合わせ',
                type_4_4: 'チャレンジ企画',
                type_4_5: '面白い実験',
                type_5: 'インタビュー系',
                type_5_1: 'ユーザー実体験',
                type_5_2: 'ストリートインタビュー',
                type_5_3: '専門家インタビュー',
                type_5_4: 'セレブリティ推薦',
                type_6: 'ドキュメンタリー系',
                type_6_1: '製造工程',
                type_6_2: '日常業務記録',
                type_6_3: 'メイキング映像',
                type_6_4: 'イベントドキュメント',
                type_7: '動画編集系',
                type_7_1: '商品ハイライト集',
                type_7_2: 'ユーザーフィードバック集',
                type_7_3: '印象的な瞬間編集',
                type_7_4: 'イベント振り返り',
                type_8: 'ライブ配信再生系',
                type_8_1: 'ライブ配信ハイライト',
                type_8_2: 'Q&Aハイライト集',
                type_8_3: '商品説明再生',
                type_8_4: 'イベントライブ編集'
            }
        },

        Tips:{
            title:'05 グローバルクリエイティブケース',
            mode:{
                title1:'タスク検索',
                title2:'クリエイティブ検索'
            },
            input:{
                label1_1:'マーケティングタスクを入力してケースを検索：',
                label1_2:'マーケティングクリエイティブを入力してケースを検索：',
                placeholder1_1:'Nikeは24年8月にPegasus41のプロモーションを継続予定。製品愛称はペガサスで、主にランナー向け。初心者向けの優れたランニングシューズであり、日常のランニングトレーニングの基本モデルとしても、カジュアルコーディネートや都市部での軽いハイキング用の必需品としても適しています。価格は手頃で、ターゲットは18-35歳、ブランドと製品に忠実で、活力のある都市部の若者です。',
                placeholder1_2:'マックとクロックスがまた新鮮なコラボ！8月28日、マクドナルドはトレンドブランドCROCSとコラボし、シリーズグッズを展開。クロックスのクロッグをインスピレーションに、縮小版のクロッグチャームを10種類製作。ハンバーガー、フライドポテトなど、豊富なデザインとアイデアが特徴。また、グッズデザインにはマクドナルドの象徴的な赤と黄色のカラーと特徴的なスマイルが鮮やかに表現され、ユーモアあふれる可愛らしさが特徴。さらに細部まで、多くのクロッグチャームにはマクドナルドのハッピーセットのロゴが付いています。キャンペーン期間中、ハッピーセットを購入するとランダムで1種類のクロッグチャームが当たります。昨年のマクドナルドとCROCSのコラボは、ソーシャルメディアで大きな注目を集めました。マックALL STARチャンピオン従業員たちがコラボモデルを着用し、ファッション誌バザーに登場。今年のマクドナルドとCROCSのコラボも引き続きファッショントレンド路線で、ファッショナブルなチャームの製作を通じて、ブランドイメージを深く印象付けています。'
            },
            filter:{
                label1:'海外事例',
                tooltip1:'オンにすると海外の事例のみ検索',
                label3:'中華圏事例',
                tooltip3:'オンにすると検索結果に中華圏の事例を含む',
                label2:'他業種',
                tooltip2:'オンにすると同業種の事例は結果に表示されない',
            },
            example1_1:{
                marketingTask:'霸王茶姬は江蘇省で蘇州博物館とのコラボレーションを予定しており、ローカライズされたマーケティングコミュニケーションを展開します。蘇州博物館の建築美学の特徴を深く掘り下げ、コラボレーションの戦略的方向性を見出し、霸王茶姬のお茶を通じた交流というコンセプトと組み合わせて、消費者に霸王茶姬のローカライズされたブランド品質を伝えます。'
            },
            example2_1:{
                marketingTask:'スターバックス x team Wang コラボ会員グッズシリーズプロモーション スターバックスはteam Wangと手を組み、クロスオーバーコラボレーションを通じて、スターバックス会員に独自のコラボグッズシリーズを提供します。今回の活動は両ブランドの特徴を組み合わせ、革新的なデザインと高品質な製品で、若い消費者の個性的でファッショナブルなライフスタイルへの追求を満たします。スターバックスブランドと若い消費者との感情的なつながりを強化します。会員のブランドロイヤルティを高め、会員のアクティビティを向上させます。スターバックス x team Wang コラボ会員グッズシリーズを推進し、製品販売を増加させます。ターゲット層はスターバックスの忠実な会員および潜在的会員です。team Wangのデザインスタイルに共感する若い層。主に中国のソーシャルプラットフォームとオンラインモールを活用してプロモーションを行い、スターバックス会員のアクティビティと新規会員数を増加させます。競合ブランド：ラッキンコーヒー/ Manner コーヒー。'
            },
            example1_2:{
                marketingTask:'8月23日、KFCと乙女ゲーム恋与深空（Love and Deepspace）のコラボが開始。今回のコラボには2つのセットメニューが含まれ、KFCと恋与深空が共同で「恋与深空ときめきセット」と「恋与深空幸せセット」を発売し、オンラインおよびテーマ店舗限定で販売され、"美味しいKFCで、幸せな人生を"というスローガンを掲げています。セット購入で対応する特典がもらえ、バッジセット/カードセット、およびゲーム内交換特典が含まれます。公式発表によると、8月29日-9月2日の期間中、コラボポップアップが上海北外灘に登場予定です。23日にコラボセットが正式発売された後、ハンター嬢（恋与深空ゲームプレイヤー）の熱意により、#KFC#が崩れたがWeibo（中国版Twitter）のトレンド入りを果たしました。その後ソーシャルメディアで広まったブランド内部からリークされた関連規定は、KFCの今回のコラボへの熱意を示しています。ネットユーザーの熱評："手に入らないことが現時点でのKFCの最大の問題"。'
            },
            example2_2:{
                marketingTask:'8月26日、楽楽茶は"熱さ対策パートナー"新商品を正式発売し、関連グッズも展開。今回のプロジェクトは"熱い"という概念から派生し、40度の高温天気による外部の暑さだけでなく、仕事などによる内部の"火照り"も対象とし、新商品"火消し菊花六宝茶"、"大橘青提"で皆さんの火を消します。セットメニュー名も"火遊びNG"セット、"適時給水"セットと、"火消し"を象徴的に強調。火元から離れ、適時に冷却し、生活はもう"燃えやすく爆発しやすい"状態ではありません。グッズには：ステッカー、消防カップ、織りバッグがあります。'
            },
            button:{
                start:'開始',
                clear:'クリア'
            },
            message:{
                error1:'マーケティングタスクを入力してください'
            }
        },
        Explosion:{
            title:'06 究極のクリエイティブブレインストーミング',
            mode:{
                title1:'IDEA 探索',
                title2:'IDEA 拡張'
            },
            input:{
                label1_1:'今回のマーケティングタスクを入力：',
                label1_2:'完成したIDEAを入力：',
                placeholder1_1:'Under armourは2024年に新しい靴slipspeedを発売予定です。主なセールスポイントは、かかとが折りたため、折りたたむと普段使いのスリッパとして、かかとを立てるとプロフェッショナルなトレーニングシューズとして使用できます。ブランドはこの新製品発売を通じて、製品のプロモーションと同時に、消費者の中でのハードコアプロフェッショナルというブランドイメージを突破し、より多くの層に拡大したいと考えています。この靴の対象年齢は18-30歳で、主な競合製品はNike Metcon 9、Reebok Nano X4です。',
                placeholder1_2:'Appleはクリエイティブエージェンシー、Hungry Man/Los Angelesと手を組み、2024年パラリンピック・パリ大会を機に、インスピレーショナル短編『The Relay』を制作。この広告は障がい者と健常者のアスリートが並んで競技する革新的なシーンを展開し、Appleデバイスを通じて、スポーツに境界はなく、テクノロジーが全ての人に平等に力を与えることを強調しています。短編は水泳、ランニング、自転車など4つの激しくも心温まる競技を通じて、障壁を乗り越える強さも、勝利への渇望も、全てのアスリートの鼓動と汗は同じように熱いことを証明しています。『The Relay』はiPhoneとApple Watchの機能展示だけでなく、人間性への共感と社会の包摂性を讃える歌でもあり、Appleの製品設計理念—唯一無二のあなたのために丁寧に作られる—を力強く伝えています。'
            },
            filter:{
                label1_1:'より庶民的に',
                label1_2:'よりグローバルに',
                label2:'より想像的に'
            },
            example1_1:{
                marketingTask:'ネスレのアラビカコーヒー品種「Star 4」は、低炭素排出とサプライチェーンの最適化を強調し、特に環境意識が高く品質を重視するコーヒー愛好家を引きつけ、中国市場での生活の質と環境の持続可能性を重視する都市部の人々を正確にターゲットとしています。このコーヒーは独特な風味を持つだけでなく、気候変動への対応における革新性を特に強調し、日常的な飲用や特別な機会での飲用に適しています。他の高級コーヒーブランドと比較して、ネスレの環境保護における革新性、品質管理、およびグローバルサプライチェーンの最適化における優位性を強調しています。'
            },
            example2_1:{
                marketingTask:'ファミリーマートの「新学期キャンペーン」活動内容：ファミリーマートは小紅書（中国版Instagram）で大学生向けの「新学期キャンペーン」を開催し、様々な割引や特典情報を発信すると同時に、小紅書上で質問回答形式の交流を行い、学生からの様々な質問に答えます。明確な目標：大学生層を正確にターゲットとし、活動デザインは彼らのニーズと好みに合わせています。プラットフォーム交流：小紅書のコミュニティ交流機能を活用し、ユーザーの参加度とブランドの好感度を向上させています。実用性が高い：実際の割引と特典を提供し、直接的に店舗の売上を向上させます。競合ブランド：セブンイレブン、ライフ、ファミリーマートの新学期キャンペーン。'
            },
            example1_2:{
                marketingTask:'ラッキンコーヒーは国産ゲーム大作『Black Myth: Wukong』とクロスオーバーし、8月19日に衝撃的に"雲乗りアメリカーノ"ドリンクを発売。この革新的な作品はレモンココナッツクラウドとIIACゴールド賞受賞コーヒー豆を融合させ、味覚の新鮮な冒険をもたらします。同時に公開された短編は、全ての"天命の者"を雲上の旅へと誘い、運命への挑戦を促します。さらにラッキンは孫悟空の新しいイメージで全国の店舗を装飾し、隠しイースターエッグとして—カスタマイズされた"瑞"の文字がついたひょうたん型パッケージを用意し、エネルギー注入の意味を込めています。また、指定セットを購入すると美しいブラックミス3D限定A4レンチキュラーカードがプレゼントされ、限定グッズは急速に完売し、非凡な人気を示しています。コラボテーマショップが杭州に出現し、ゲームの実景を再現し、没入型体験があなたの探索を待っています。コーヒーと神話が融合する幻想的な魅力を一味。'
            },
            example2_2:{
                marketingTask:'蜜雪氷城は革新的なマーケティングを展開し、実写版ショートドラマ『雪王のタイムスリップ日記』を公開し、ブランドストーリーの新トレンドを牽引。雪王が様々な役柄に変身してタイムスリップ冒険を繰り広げ、各回2分の緊密なストーリー展開で、ユーモアにあふれ、笑いを誘います。すでに放送された4話はそれぞれ異なる魅力：雪のダンスキングから雪の執事、雪の秘書、雪のアイドルまで、雪王の多面的な魅力を展開。メイキング映像にも誠意が溢れ、監督と雪王の息の合った交流、心温まる笑いが絶えず、ファンとの感情的つながりを深め、ブランドコンテンツマーケティングの新基準を打ち立てています。'
            }
        },
        ImageGenerator: {
            goExperience: '今すぐ体験',
            quickGenerate: 'マーケティングコンセプト画像生成',
            dialog: {
                hintText: '画像生成のクリエイティブを入力してください：',
                placeholder: 'マーケティングコンセプトを入力してください。画像の内容を詳細に説明する必要はありません。例：マクドナルドのスパイシーチキンバーガーがワールドカップのスポンサーに',
                deleteConfirm: 'この画像を削除してもよろしいですか？'
            },
            state: {
                pending: '準備中',
                processing: '生成中',
                completed: '完了',
                failed: '失敗'
            },
            table: {
                hint: '画像内容の説明',
                createTime: '作成日時',
                image: '画像'
            },
            component: {
                button: {
                    submit: '確定',
                    generate: '新規生成'
                }
            },
            message: {
                deleteSuccess: '削除成功',
                deleteFailed: '削除失敗'
            }
        }
    },
    components: {
        PageHeader: {
            userCenter:'会員センター'
        },
        WaterfallPage: {
            button: {
                example: '参考例',
                editBrief: 'ブリーフ修正',
                generatePlan: 'ブレインストーム',
                finishEdit: '修正完了',
                editPlan: 'もっとアイデアを',
                fullPlan:'完成プラン',
                export:'ダウンロード形式',
                wordCn:'Word（中国語）',
                wordEn:'Word（英語）',
                freeMind:'FreeMind',
                markdown:'Markdown'
            },
            mode:{
                crazy:'--クレイジーアイデアモード'
            },
            input:{
                title:'今回のADGoのタスク：'
            },
            brief: {
                title: 'ブリーフ文書',
                viewSource: '情報ソースを見る',
                anchor: {
                    title: 'ブリーフ'
                }
            },
            plan: {
                anchor: {
                    title1: 'ブレインストーム',
                    title2: '完全プラン'
                }
            },
            dialog: {
                content1: 'ブリーフを確認してください',
                content2: 'ブレインストームを開始しますか？',
                ok: '確認',
                cancel: 'もう少し考える'
            },
            dialog3: {
                content1: 'ブリーフを確認してください',
                content2: 'プラン生成を開始しますか？',
            },
            complete:{
                message1:'AIは頑張って作業を完了しました！じっくりお読みください。'
            },
            waiting:{
                message1:'AIが考え中です。コーヒーでも飲みながら{waitingTime}分ほどお待ちください',
                message2:'（順番待ちがあります。しばらくお待ちください）'
            },
            waiting2:{
                message1:'AIがブレインストーム中です。タピオカミルクティーでも飲みながら{waitingTime}分ほどお待ちください',
                message2:'（順番待ちがあります。しばらくお待ちください）'
            },
            error1:{
                message1:'申し訳ありません。AIはプランをどこに置いたか忘れてしまいました',
                message2:'（システムエラー。再生成します）',
                redoTask:'再生成'
            },
            error2:{
                message1:'申し訳ありません。AIが体調不良で帰宅しました。緊急でボス"張さん"に連絡してください。',
                message2:'（システムエラー。以下の情報を送信してください：'
            },
            error3: {
                title:'機能制限のお知らせ',
                message1: '申し訳ありません。AIは全面的にアップグレードされました。この機能を使用するには、同じタスクを再度開始する必要があります。',
            },
            dialog2: {
                content1: '現在のメイン推奨アイデアで完全プランを生成しますか？',
                content2: '「ブレインストーム」ボタンをクリックしてより多くのアイデア推奨を取得',
                button1: 'ブレインストーム',
                button2: 'コミュニケーションプラン',
                button3: 'KOLプラン'
            },
            autoSave:'自動保存成功'
        },
        UsagePage:{
            ToolBar:{
                title:"使用状況",
                self:"自分",
                others:"他のメンバー"
            },
            chart:{
                title1:'月間消費',
                title2:'総消費'
            },
            circle:{
                title:'消費/残量'
            },
            BalanceTable:{
                title1:'取得日時',
                title2:'状態',
                title3:'残高',
                title4:'有効期限',
                status1:'期限切れ',
                status2:'利用可能'
            },
            TaskTable:{
                title:'実際の使用状況',
                notice:{
                    content1:'トークン消費説明',
                    content2:'プラン',
                    content3:'翻訳',
                },
                title1:'機能',
                title2:'タイプ',
                title3:'回数'
            }
        },
        ShortVideoScript:{
            brief:{
                scriptType:'脚本タイプ：',
                scriptCondition:'撮影条件：'
            },
            title1:'商品のセールスポイント整理（撮影したい3つのセールスポイントを選択してください）：',
            title2:'トップ10ユーザー購入ポイント（撮影したい3つの購入ポイントを選択してください）：',
            title3:'セールスポイント【{sellPoint}】と購入ポイント【{painPoint}】に基づいて生成されたアイデア：',
            title4: 'ショート動画脚本：',
            title5: '審査意見：',
            button: {
                getPainPoints:'購入ポイントを掘り下げる',
                getCreativitys:'アイデアを出す',
                moreCreativitys:'もう1つアイデアを出す',
                getScripts:'脚本を出す',
                ok:'はい',
                cancel:'もう一度選ぶ',
                downloadScript: '脚本をダウンロード',
                selectSellPoint: 'アイデアを再生成'
            },
            anchor:{
                text1:'セールスポイント整理',
                text2:'ユーザー購入ポイント',
                text3:'脚本アイデア',
                text4:'撮影脚本',
            },
            msgBox1:{
                title:'お知らせ',
                content:'選択した商品のセールスポイントを使用して、ADGoでユーザーの購入ポイントをさらに掘り下げますか？'
            },
            msgBox2:{
                title:'お知らせ',
                content:'選択したユーザー購入ポイントに基づいて、今回のプロモーション動画のアイデアを作成しますか？'
            },
            msgBox3:{
                title:'お知らせ',
                content:'脚本アイデアをもう1つ作成しますか？'
            },
            msgBox4:{
                title:'お知らせ',
                content:'選択した脚本アイデアに基づいて、今回のプロモーション動画の最終脚本を作成しますか？'
            }
        },
        Tips:{
            button:{
                exportWord:'ファイルをダウンロード',
                moreCases:'既存の次元を使用',
                newDimension:'次元を更新',
                change:'さらなる事例',
                getTips:'事例の二次創作を生成',
                generate: '新規生成'
            },
            dimensionIndex:'第{i}バッチ参考事例',
            dimension:'ADGo推奨事例の次元：',
            source :'ソース：',
            reference: '参考事例：',
            tips:'事例の二次創作',
            modal:{
                change:{
                    label:{
                        core:'コアキーワード',
                        crowd:'対象者キーワード',
                        industry:'業界キーワード',
                        platform:'プラットフォームキーワード',
                        strategy:'クリエイティブ戦略キーワード',
                        format:'コンテンツ形式キーワード'
                    },
                    placeHolder:{
                        core:'コア：ハロウィン、グァン・ハン、ハリーポッター など',
                        crowd:'対象者：都市部のホワイトカラー女性、地方出身の大学卒業生、35-45歳の企業幹部 など',
                        industry:'業界：FMCG、スポーツブランド、旅行、自動車 など',
                        platform:'プラットフォーム：WeChat、TikTok、Weibo、Zhihu など',
                        strategy:'クリエイティブ戦略：感情マーケティング、プロモーション、教育コンテンツ、ストーリーテリング など',
                        format:'コンテンツ形式：ショート動画、画像付きテキスト、公式アカウント記事、文章 など'
                    },
                    rules:{
                        crowd:'対象者キーワードは空にできません',
                        industry:'業界キーワードは空にできません',
                        platform:'プラットフォームキーワードは空にできません',
                        strategy:'クリエイティブ戦略キーワードは空にできません',
                        format:'コンテンツ形式キーワードは空にできません'
                    }
                }
            },
            warnings:{
                getTips:'参考事例を選択してください',
                moreCases:'次元のセットを選択してください',
                maxCount:'参考事例は最大3バッチまでしか生成できません',
                keywords:'キーワードを入力してください'
            }
        },
        Explosion: {
            button:{
                exportWord:'ファイルをダウンロード',
                moreCases:'既存のキーワード',
                newDimension:'キーワードを更新',
                change:'さらなるIDEA',
                getTips:'IDEAの二次創作'
            },
            dimensionIndex:'第{i}バッチIDEA',
            dimension:'ADGo推奨キーワード：',
            tips:'IDEAの二次創作',
            modal:{
                change:{
                    label:{
                        core:'コアキーワード',
                        crowd:'対象者キーワード',
                        industry:'業界キーワード',
                        platform:'プラットフォームキーワード',
                        strategy:'クリエイティブ戦略キーワード',
                        format:'コンテンツ形式キーワード'
                    },
                    placeHolder:{
                        core:'コア：ハロウィン、リウ・イーフェイ、ハリーポッター など',
                        crowd:'対象者：都市部のホワイトカラー女性、地方出身の大学卒業生、35-45歳の企業幹部 など',
                        industry:'業界：FMCG、スポーツブランド、旅行、自動車 など',
                        platform:'プラットフォーム：WeChat、TikTok、Weibo、Zhihu など',
                        strategy:'クリエイティブ戦略：感情マーケティング、プロモーション、教育コンテンツ、ストーリーテリング など',
                        format:'コンテンツ形式：ショート動画、画像付きテキスト、公式アカウント記事、文章 など'
                    },
                    rules:{
                        crowd:'対象者キーワードは空にできません',
                        industry:'業界キーワードは空にできません',
                        platform:'プラットフォームキーワードは空にできません',
                        strategy:'クリエイティブ戦略キーワードは空にできません',
                        format:'コンテンツ形式キーワードは空にできません'
                    }
                }
            },
            warnings:{
                getTips:'二次創作するIDEAを選択してください',
                moreCases:'キーワードを選択してください',
                maxCount:'IDEAは最大4バッチまでしか生成できません',
                keywords:'キーワードを入力してください'
            },
            confirm:{
                title:'生成確認',
                content:'さらにIDEAを生成して拡張しますか？'
            }
        }
    }
}
